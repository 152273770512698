var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "addReport", staticClass: "New__Entity" },
    [
      _c("div", { staticClass: "SideMenu__Header" }, [
        _c("h1", [_vm._v("הוספת דוח")]),
        _c("div", [
          _vm.isAdmin
            ? _c("div", { staticClass: "NewRepornEntity__Component__Qrcode" }, [
                _c(
                  "div",
                  {
                    staticClass: "NewRepornEntity__Component__Qrcode__Icon",
                    on: {
                      click: function($event) {
                        _vm.isOpenCamera = true
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      attrs: { size: "is-large", icon: "qrcode" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.isOpenCamera
            ? _c(
                "div",
                { staticClass: "qrcoder-conteiner" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "close-btn",
                      on: {
                        click: function($event) {
                          ;(_vm.isOpenCamera = false), (_vm.isLoading = false)
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { size: "is-large", icon: "close" }
                      })
                    ],
                    1
                  ),
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "qrcoder-loader" },
                        [
                          _vm.isLoading
                            ? _c("ClipLoader", {
                                attrs: {
                                  color: "#2e3f50",
                                  loading: _vm.isLoading,
                                  size: 220
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("qrcode-stream", {
                    on: { decode: _vm.onDecode, init: _vm.onCamera }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "SideMenu__Content" }, [
        _c(
          "div",
          { staticClass: "RightSide__Content" },
          [
            _c("FieldInlineSelect", {
              attrs: {
                "hide-hover": true,
                label: "עיריה",
                tabIndex: 1,
                clearable: true,
                filterable: true,
                "null-is-value": true,
                placeholder: "בחר עיריה מהרשימה",
                optionsAsync: _vm.getMunicipalities,
                required: _vm.$v.model.Municipality.$error
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.$v.model.Municipality.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.model.Municipality, "$model", $$v)
                },
                expression: "$v.model.Municipality.$model"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "מזהה עיריה", tabIndex: 2 },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.MuniIdNumber,
                callback: function($$v) {
                  _vm.$set(_vm.model, "MuniIdNumber", $$v)
                },
                expression: "model.MuniIdNumber"
              }
            }),
            _c("FieldInlineSelect", {
              attrs: {
                "alert-not-exist": true,
                "hide-hover": true,
                label: "רכב",
                tabIndex: 3,
                clearable: true,
                filterable: true,
                "null-is-value": true,
                placeholder: "הקלד לוחית רישוי",
                options: [],
                "fetch-options": _vm.onSearchVehicles,
                required: _vm.$v.model.Vehicle.$error,
                reduce: function(item) {
                  return item
                }
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.$v.model.Vehicle.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.model.Vehicle, "$model", $$v)
                },
                expression: "$v.model.Vehicle.$model"
              }
            }),
            _c("FieldInlineText", {
              attrs: {
                label: "מספר דוח",
                tabIndex: 4,
                required: _vm.$v.model.ReportNumber.$error
              },
              on: { focus: _vm.detectTabPress, onChange: _vm.onChange },
              model: {
                value: _vm.$v.model.ReportNumber.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.model.ReportNumber, "$model", $$v)
                },
                expression: "$v.model.ReportNumber.$model"
              }
            }),
            _c("FieldInlineDateTime", {
              staticClass: "date",
              attrs: {
                label: "תאריך העבירה",
                tabindex: "6",
                required: _vm.$v.model.DateTaken.$error
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.$v.model.DateTaken.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.model.DateTaken, "$model", $$v)
                },
                expression: "$v.model.DateTaken.$model"
              }
            }),
            _c("FieldInlineDate", {
              staticClass: "date",
              attrs: { label: "תאריך אחרון לתשלום", tabIndex: 7 },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.LastPayDate,
                callback: function($$v) {
                  _vm.$set(_vm.model, "LastPayDate", $$v)
                },
                expression: "model.LastPayDate"
              }
            }),
            _c("FieldInlineDate", {
              staticClass: "date",
              attrs: { label: "תאריך אימות", tabIndex: 8 },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.VerificationDate,
                callback: function($$v) {
                  _vm.$set(_vm.model, "VerificationDate", $$v)
                },
                expression: "model.VerificationDate"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "LeftSide__Content" },
          [
            _c("FieldInlineSelect", {
              attrs: {
                "hide-hover": true,
                label: "שולם",
                clearable: false,
                filterable: false,
                placeholder: "לא",
                tabIndex: 9,
                "null-is-value": true,
                options: [
                  { Value: false, Text: "לא" },
                  { Value: true, Text: "כן" }
                ]
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.IsPaid,
                callback: function($$v) {
                  _vm.$set(_vm.model, "IsPaid", $$v)
                },
                expression: "model.IsPaid"
              }
            }),
            _vm.model.IsPaid
              ? _c(
                  "div",
                  [
                    _c("FieldInlineText", {
                      attrs: {
                        label: "אסמכתה",
                        required: _vm.$v.paymentModel.Reference.$error
                      },
                      on: { onChange: _vm.onChange },
                      model: {
                        value: _vm.$v.paymentModel.Reference.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.paymentModel.Reference, "$model", $$v)
                        },
                        expression: "$v.paymentModel.Reference.$model"
                      }
                    }),
                    _c("FieldInlineText", {
                      attrs: {
                        label: "תאריך תשלום",
                        required: _vm.$v.paymentModel.PaymentDate.$error
                      },
                      on: { onChange: _vm.onChange },
                      model: {
                        value: _vm.$v.paymentModel.PaymentDate.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.paymentModel.PaymentDate,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.paymentModel.PaymentDate.$model"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("FieldInlineText", {
              attrs: {
                label: "סכום מקורי",
                tabIndex: 10,
                required: _vm.$v.model.OriginalAmount.$error
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.$v.model.OriginalAmount.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.model.OriginalAmount, "$model", $$v)
                },
                expression: "$v.model.OriginalAmount.$model"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "יתרה לתשלום", tabIndex: 11 },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.CurrentAmount,
                callback: function($$v) {
                  _vm.$set(_vm.model, "CurrentAmount", $$v)
                },
                expression: "model.CurrentAmount"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "כתובת", tabIndex: 12 },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.Address,
                callback: function($$v) {
                  _vm.$set(_vm.model, "Address", $$v)
                },
                expression: "model.Address"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "תקנה", tabIndex: 13 },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.RelevantLaw,
                callback: function($$v) {
                  _vm.$set(_vm.model, "RelevantLaw", $$v)
                },
                expression: "model.RelevantLaw"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "תיאור המקרה", tabIndex: 14 },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.Reason,
                callback: function($$v) {
                  _vm.$set(_vm.model, "Reason", $$v)
                },
                expression: "model.Reason"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "b-notification",
        {
          staticClass: "notification",
          attrs: { type: "is-info", "aria-close-label": "Close notification" },
          model: {
            value: _vm.reportIsAlreadyExists,
            callback: function($$v) {
              _vm.reportIsAlreadyExists = $$v
            },
            expression: "reportIsAlreadyExists"
          }
        },
        [
          _vm._v(" הדוח כבר קיים במערכת, על מנת לטעון את הדוח הקיים "),
          _c(
            "router-link",
            { attrs: { to: "reports/" + _vm.model.ReportNumber } },
            [_vm._v("לחצו כאן")]
          )
        ],
        1
      ),
      _c(
        "b-notification",
        {
          staticClass: "notification",
          attrs: {
            type: "is-warning",
            "aria-close-label": "Close notification"
          },
          model: {
            value: _vm.reportWasntFound,
            callback: function($$v) {
              _vm.reportWasntFound = $$v
            },
            expression: "reportWasntFound"
          }
        },
        [_vm._v(" לא הצלחנו למצוא את נתוני הדוח. ניתן להקליד את הדוח ידנית ")]
      ),
      _c(
        "div",
        { staticClass: "SideMenu__Footer" },
        [
          _c(
            "div",
            [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { disabled: _vm.isLoading, type: "" },
                  on: { click: _vm.onCancel }
                },
                [_vm._v(" נקה ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "create",
                  attrs: { loading: _vm.isLoading, tabindex: "15", type: "" },
                  on: {
                    click: function($event) {
                      return _vm.onSave(false)
                    }
                  }
                },
                [_vm._v(" שמור ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "create",
                  attrs: { loading: _vm.isLoading, type: "" },
                  on: {
                    click: function($event) {
                      return _vm.onSave(true)
                    }
                  }
                },
                [_vm._v(" שמור ללא וידוא ")]
              )
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "close",
              attrs: {
                tabindex: "5",
                disabled:
                  _vm.isLoading ||
                  !_vm.model.Vehicle ||
                  !_vm.model.ReportNumber,
                loading: _vm.isLoading,
                type: ""
              },
              on: { click: _vm.fetchReportData }
            },
            [_vm._v(" משוך נתוני דוח ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }