<template>
    <div ref="addReport" class="New__Entity">
        <div class="SideMenu__Header">
            <h1>הוספת דוח</h1>
            <div>
                <div v-if="isAdmin" class="NewRepornEntity__Component__Qrcode">
                    <div class="NewRepornEntity__Component__Qrcode__Icon" @click="isOpenCamera = true">
                        <b-icon size="is-large" icon="qrcode"> </b-icon>
                    </div>
                </div>
                <div v-if="isOpenCamera" class="qrcoder-conteiner">
                    <div class="close-btn" @click="(isOpenCamera = false), (isLoading = false)">
                        <b-icon size="is-large" icon="close"></b-icon>
                    </div>
                    <div v-if="isLoading" class="qrcoder-loader">
                        <ClipLoader color="#2e3f50" v-if="isLoading" :loading="isLoading" :size="220" />
                    </div>
                    <qrcode-stream @decode="onDecode" @init="onCamera" />
                </div>
            </div>
        </div>
        <div class="SideMenu__Content">
            <div class="RightSide__Content">
                <FieldInlineSelect :hide-hover="true" label="עיריה" :tabIndex="1" :clearable="true" :filterable="true"
                    :null-is-value="true" placeholder="בחר עיריה מהרשימה" :optionsAsync="getMunicipalities"
                    @onChange="onChange" :required="$v.model.Municipality.$error" v-model="$v.model.Municipality.$model" />
                <FieldInlineText label="מזהה עיריה" @onChange="onChange" v-model="model.MuniIdNumber" :tabIndex="2" />
                <FieldInlineSelect :alert-not-exist="true" :hide-hover="true" label="רכב" :tabIndex="3" :clearable="true"
                    :filterable="true" :null-is-value="true" placeholder="הקלד לוחית רישוי" :options="[]"
                    :fetch-options="onSearchVehicles" @onChange="onChange" :required="$v.model.Vehicle.$error"
                    :reduce="(item) => item" v-model="$v.model.Vehicle.$model" />

                <FieldInlineText @focus="detectTabPress" label="מספר דוח" :tabIndex="4" @onChange="onChange"
                    :required="$v.model.ReportNumber.$error" v-model="$v.model.ReportNumber.$model" />

                <FieldInlineDateTime class="date" label="תאריך העבירה" @onChange="onChange" v-model="$v.model.DateTaken.$model"
                    tabindex="6" :required="$v.model.DateTaken.$error" />
                <FieldInlineDate class="date" label="תאריך אחרון לתשלום" @onChange="onChange" v-model="model.LastPayDate"
                    :tabIndex="7" />
                <FieldInlineDate class="date" label="תאריך אימות" @onChange="onChange" v-model="model.VerificationDate" :tabIndex="8" />
            </div>
            <div class="LeftSide__Content">
                <FieldInlineSelect :hide-hover="true" label="שולם" :clearable="false" :filterable="false" placeholder="לא"
                    :tabIndex="9" :null-is-value="true" :options="[
                        { Value: false, Text: 'לא' },
                        { Value: true, Text: 'כן' },
                    ]" @onChange="onChange" v-model="model.IsPaid" />
                <div v-if="model.IsPaid">
                    <FieldInlineText label="אסמכתה" :required="$v.paymentModel.Reference.$error" @onChange="onChange" v-model="$v.paymentModel.Reference.$model" />
                    <FieldInlineText label="תאריך תשלום" :required="$v.paymentModel.PaymentDate.$error" @onChange="onChange"
                        v-model="$v.paymentModel.PaymentDate.$model" />
                </div>
                <FieldInlineText label="סכום מקורי" @onChange="onChange" :tabIndex="10"
                    :required="$v.model.OriginalAmount.$error" v-model="$v.model.OriginalAmount.$model" />
                <FieldInlineText label="יתרה לתשלום" @onChange="onChange" :tabIndex="11" v-model="model.CurrentAmount" />
                <FieldInlineText label="כתובת" @onChange="onChange" :tabIndex="12" v-model="model.Address" />
                <FieldInlineText label="תקנה" @onChange="onChange" :tabIndex="13" v-model="model.RelevantLaw" />
                <FieldInlineText label="תיאור המקרה" @onChange="onChange" :tabIndex="14" v-model="model.Reason" />
            </div>
        </div>
        <b-notification v-model="reportIsAlreadyExists" type="is-info" class="notification"
            aria-close-label="Close notification">
            הדוח כבר קיים במערכת, על מנת לטעון את הדוח הקיים
            <router-link :to="'reports/' + model.ReportNumber">לחצו כאן</router-link>
        </b-notification>
        <b-notification v-model="reportWasntFound" type="is-warning" class="notification"
            aria-close-label="Close notification">
            לא הצלחנו למצוא את נתוני הדוח. ניתן להקליד את הדוח ידנית
        </b-notification>
        <div class="SideMenu__Footer">
            <div>
                <button :disabled="isLoading" @click="onCancel" class="close" type="">
                    נקה
                </button>
                <b-button :loading="isLoading" @click="onSave(false)" tabindex="15" class="create" type="">
                    שמור
                </b-button>
                <b-button :loading="isLoading" @click="onSave(true)" class="create" type="">
                    שמור ללא וידוא
                </b-button>
            </div>
            <b-button tabindex="5" :disabled="isLoading || !model.Vehicle || !model.ReportNumber" :loading="isLoading"
                @click="fetchReportData" class="close" type="">
                משוך נתוני דוח
            </b-button>
        </div>
    </div>
</template>

<script>
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
// import FieldInlineTime from '@/components/Fields/FieldInlineTime.vue';
import FieldInlineDate from "@/components/Fields/FieldInlineDate.vue";
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import MunicipalitiesService from "@/services/MunicipalitiesService";
import VehicleService from "@/services/VehicleService";
import { requiredIf, required } from "vuelidate/lib/validators";
import ReportService from "@/services/ReportService";
import moment from "moment";
import { QrcodeStream } from "vue-qrcode-reader";
// import { ContentLoader } from "vue-content-loader";
import { ClipLoader } from "@saeris/vue-spinners";

export default {
    name: "NewReportEntity",
    props: ["isAdminArea"],
    components: {
        FieldInlineSelect,
        FieldInlineText,
        FieldInlineDate,
        // FieldInlineTime,
        FieldInlineDateTime,
        QrcodeStream,
        // ContentLoader,
        ClipLoader,
    },
    validations: {
        paymentModel: {
            Reference: {
                required: requiredIf(function () {
                    return this.model.IsPaid;
                }),
            },
            PaymentDate: {
                required: requiredIf(function () {
                    return this.model.IsPaid;
                }),
            },
        },
        model: {
            Municipality: {
                required,
            },
            Vehicle: {
                required,
            },
            ReportNumber: {
                required,
            },
            DateTaken: {
                required,
            },
            OriginalAmount: {
                required,
            },
            // IsPaid: {
            //     required,
            // },
        },
    },
    data() {
        return {
            reportIsAlreadyExists: false,
            reportWasntFound: false,
            isLoading: false,
            model: {
                ReportNumber: null,
                IsPaid: false,
                VerificationDate: null,
                Vehicle: null,
                Municipality: null,
                MuniIdNumber: null,
                LastPayDate: null,
                OriginalAmount: null,
                CurrentAmount: null,
                Reason: null,
                RelevantLaw: null,
                DateTaken: new Date(),
                Address: null,
            },

            paymentModel: {
                Reference: null,
                PaymentDate: null,
            },
            isOpenCamera: false,
        };
    },
    computed: {
        isAdmin() {
            return this.$store.state.auth?.user?.claims?.isAdmin;
        },
    },
    methods: {
        async onDecode(url) {
            const encodedUrl = encodeURIComponent(url);
            ReportService.getReportQrDetails(encodedUrl)
                .then((res) => {
                    this.model.Municipality = res.data.MunicipalityId;
                    this.model.Vehicle = res.data.PlateNumber;
                    this.model.ReportNumber = res.data.ReportNumber;

                    this.fetchReportData();
                })
                .catch((err) => {
                    console.log(err);
                });
            this.isOpenCamera = false;
        },
        onCamera(promise) {
            this.isLoading = true;
            promise
                .then((res) => {
                    this.isLoading = false;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    this.error = err;
                });
        },
        loadReport() {
            ReportService.loadReportByNumber(
                this.model.ReportNumber,
                this.model.Municipality
            ).then((r) => {
                this.model = { ...r.data };
                this.model.Municipality = r.data.Municipality?.Id;
                // this.$v.model.Municipality = r.data.Municipality?.Id;
                // צריך לעשות וואלידציה
                this.model.Vehicle = r.data.Vehicle?.Id;
                this.model.DateTaken = new Date(r.data.DateTaken);
                if (r.data.LastPayDate) {
                    this.model.LastPayDate = new Date(r.data.LastPayDate);
                }
                if (r.data.VerificationDate) {
                    this.model.VerificationDate = new Date(r.data.VerificationDate);
                }
                this.reportIsAlreadyExists = false;
            });
        },
        detectTabPress(e) {
            console.log(e);
        },
        fetchReportData() {
            this.isLoading = true;
            this.reportWasntFound = false;
            this.reportIsAlreadyExists = false;

            ReportService.fetchReportData(
                this.model.Municipality,
                this.model.Vehicle?.Text || this.model.Vehicle?.Name,
                this.model.ReportNumber
            )
                .then((r) => {
                    if (!r.data) {
                        this.$buefy.toast.open({
                            type: "is-success",
                            message:
                                "לא היה ניתן למצוא את נתוני הדוח. יש להקליד את הדוח באופן ידני",
                            duration: 3000,
                            position: "is-bottom",
                        });
                    }
                    const { data } = r;
                    this.fillModel(data);
                })
                .catch((err) => {
                    if (err) {
                        err.response?.data?.ErrorCode === 23
                            ? (this.reportIsAlreadyExists = true)
                            : (this.reportWasntFound = true);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        fillModel(data) {
            this.model.Address = data.Address;
            this.model.CurrentAmount = data.CurrentAmount;
            this.model.DateTaken = data.DateTaken ? new Date(data.DateTaken) : null;
            this.model.LastPayDate = data.LastPayDate
                ? new Date(data.LastPayDate)
                : null;
            this.model.OriginalAmount = data.OriginalAmount;
            this.model.Reason = data.Reason;
            this.model.RelevantLaw = data.RelevantLaw;
        },
        setDateTaken(value) {
            if (this.model.DateTaken) {
                const date = moment(this.model.DateTaken);
                const newDate = moment(value);
                date.date(newDate.date());
                date.month(newDate.month());
                date.year(newDate.year());
                this.model.DateTaken = date;
            } else {
                this.model.DateTaken = value;
            }
        },
        setTimeTaken(value) {
            const hour = moment(value);
            const currentDate = moment(this.model.DateTaken);
            currentDate.hour(hour.hours());
            currentDate.minute(hour.minutes());
            this.model.DateTaken = currentDate;
        },
        onChange() { },
        onSearchVehicles({ search, loading, setData, setValue }) {
            if (search.length > 4) {
                this.model.Vehicle = search;
                loading(true);
                return (
                    VehicleService.searchAdminPlateNumber(search)
                )
                    .then((r) => {
                        if (!r.data.length) {
                            setValue({ Value: -1, Text: search });
                        } else {
                            setData(r.data.map((i) => ({ Value: i.Id, Text: i.Name })));
                        }
                    })
                    .finally(() => {
                        loading(false);
                    });
            }

            return 's';
        },
        getMunicipalities() {
            return MunicipalitiesService.get;
        },
        onSave(skipVerification) {
            const self = this;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.isLoading = true;
            if (!this.model.VerificationDate) this.model.VerificationDate = new Date();
            const data = { ...this.model };
            data.DateTaken = moment(data.DateTaken).format('YYYY-MM-DD HH:mm');
            const { Vehicle, Municipality, ...rest } = data;

            rest.Municipality = { Id: this.model.Municipality };
            rest.Vehicle = {
                Id: this.model.Vehicle?.Value,
                Name: this.model.Vehicle.Text,
            };

            ReportService.createReport(rest, skipVerification)
                .then((r) => {
                    self.$buefy.toast.open({
                        type: "is-success",
                        message: `הדוח נשמר בהצלחה. לצפיה בדוח <a style="color:white;text-decoration: undeline;" href="reports/${r.data.Id}">לחץ כאן </a>`,
                        duration: 10000,
                    });
                    self.$emit("onSave");
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        type: "is-danger",
                        message: "השמירה נכשלה, יש לנסות שוב.",
                        duration: 8000,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                    this.onCancel();
                });
        },
        onCancel() {
            // this.$emit("onCancel");
            // this.$router.replace({ path: '/admin/profiles' });
            Object.keys(this.model).forEach((k) => this.model[k] = '');
            this.model.IsPaid = false;
        },
    },
};
</script>

<style scoped lang="scss">
.NewRepornEntity__Component__Qrcode {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 50px;
    width: 50px;
}

.NewRepornEntity__Component__Qrcode__Icon {
    width: 44px;
    height: 44px;
    border-radius: 5%;
    background: #eaf0f6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.InlineField__Component {
    border-bottom: 1px solid black;
}

::v-deep .date{
    border-bottom: none !important;
    border: none !important;
}

.notification {
    position: relative;
    // bottom: 43px;
    width: 100%;
    border-radius: 0;

    ::v-deep .media-content {
        text-align: center;
    }

    &.is-info {
        background-color: #01afb4;
    }
}

.New__Entity {

    // height: 100vh;
    // display: flex;
    // flex-direction: column;
    // max-width: 600px;
    // margin: 0 auto;
    .SideMenu__Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        // background: #01a6bc;
        // background: linear-gradient(90deg, #01a6bc 0%, #00baa8 100%);
        h1 {
            color: #00afd8;
            font-size: 25px;
            font-weight: 600;
        }
    }

    .SideMenu__Content {
        display: flex;
        min-height: calc(80vh - 60px);
        gap: 50px;

        &>* {
            max-width: 400px;
            padding: 0 40px;
            width: 100%;

        }
    }

    .SideMenu__Footer {
        // height: 50px;
        border-top: 1px solid silver;
        background: white;
        display: flex;
        // position: relative;
        justify-content: center;

        button {
            height: 45px;
            margin: 10px;
            padding: 10px 30px;
            border-radius: 3px;
            outline: none;
            cursor: pointer;
        }

        .close {
            background: white;
            color: #ff7a59;
            border: none;
            border: 1px solid #ff7a59;
        }

        .create {
            background: #ff7a59;
            color: white;
            border: none;
        }
    }

    // .qrcoder-conteiner {
    //     position: fixed;
    //     width: 80vw;
    //     height: 80vh;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     z-index: 10;

    //     .close-btn {
    //         position: absolute;
    //         z-index: 30;
    //         background-color: gray;
    //         color: white;
    //         cursor: pointer;
    //     }

    //     .qrcoder-loader {
    //         background: #dfdfdf;
    //         position: relative;
    //         width: 100%;
    //         height: 100%;
    //         z-index: 10;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //     }
    // }
}

@media screen and (max-width: 600px) {
    .SideMenu__Footer {
        button {
            height: 40px !important;
            margin: 5px !important;
            padding: 10px 20px !important;
        }
    }
}

@media screen and (max-width: 460px) {
    .SideMenu__Footer {
        button {
            margin: 2px !important;
            padding: 10px 10px !important;
        }
    }

}
</style>
